import React, {ReactElement} from 'react';
import {useNavigate} from "react-router-dom";

interface SoldOutProps {
    page: string;
}

const SoldOut = (props: SoldOutProps): ReactElement => {
    const navigate = useNavigate();

    switch (props.page) {
        case "plp":
            return (
                <div className={"row justify-content-center"}>
                     <div className="col-12 text-left col-lg-7 px-3">
                         <h2 className="sold-out__title text--primary-color">{"Spiacenti!"}</h2>
                     </div>
                     <div className="col-12 col-lg-7 px-3">
                         <p className="sold-out__subtitle text--heading-color">{"Ci dispiace, al momento non ci sono biglietti in vendita per nessuna partita :("}</p>
                         <p className="sold-out__subtitle last text--heading-color">{"Riprova più tardi o nei prossimi giorni."}</p>
                     </div>
                     <div className="col-12 col-lg-6 px-3">
                         <div className='text-center d-flex align-items-center justify-content-left'>
                             <button className='btn btn--gradient ml-auto px-sm-3' onClick={() => {navigate("/")}}>
                                 TORNA INDIETRO
                             </button>
                         </div>
                     </div>
                </div>
            )
        case "pdp":
            return (
                <div className="shopping-cart--empty container">
                    <div className="col-12">
                        <h2 className="shopping-cart--empty__title">{"Spiacenti"}</h2>
                    </div>
                    <div className="col-11 col-md-9 col-lg-7 col-xl-6">
                        <p className="shopping-cart--empty__subtitle">
                            {"Ci dispiace, al momento non ci sono biglietti in vendita per questa partita :("}
                        </p>
                    </div>
                    <div className="col-12">
                        <button type="button" className="shopping-cart--empty__continue btn btn--gradient"
                                onClick={() => {navigate("/Partite")}}
                            >
                            {"Torna indietro"}
                        </button>
                    </div>
                </div>
            )
        default:
            return <></>
    }

}


export default SoldOut
