import {ReactElement, useEffect} from "react";

interface ChangePasswordModalProps {
    eventLocationName: string;
    eventLocationImage: string;
    onClose: () => void;
}

const EventLocationInfoModal = (props: ChangePasswordModalProps): ReactElement => {

    useEffect(() => {
        document.body.style.position = 'fixed';

        return () => {
            document.body.style.position = 'unset';
        }
    }, [])

    return (
        <>
            <div className="modal-header">
                <div className="mx-auto text-center">
                    <h5 className="modal-title text-center">Info Settore</h5>
                    <label className="text-center">{props.eventLocationName}</label>
                </div>
                <button type="button" className="btn close p-0" data-dismiss="modal" aria-label="Close"
                        onClick={() => {
                            props.onClose()
                        }}
                >
                    <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
                </button>
            </div>
            <div className="modal-body mx-3 mx-sm-0">
                <div className="container pt-3 pt-sm-0 pb-5 d-flex justify-content-center">
                    <img src={props.eventLocationImage} alt="mappa stadio" width="90%" />
                </div>
            </div>
        </>
    )
}

export default EventLocationInfoModal
