import React, {ReactElement, useEffect, useState} from 'react'
import {useApi} from "@/hoc/Api/context";
import Event from "@/models/Event";
import Loader from "@/components/Loader";
import SoldOut from '@/components/SoldOut';
import PLPFilters from "@/components/PLPFilters";
import EventIssuer from "@/models/EventIssuer";
import PLPCard from "@/components/PLPCard";
import Layout from '@/components/Layout';
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";

const Plp = ({...props}): ReactElement => {
    const api = useApi()
    const seo = useSeo()
    const [events, setEvents] = useState<Event[] | undefined>(undefined);
    const [eventIssuers, setEventIssuers] = useState<EventIssuer[] | undefined>(undefined)
    const [loaded, setLoaded] = useState<boolean>(false);
    const [filter, setFilter] = useState<string[]>([]);
    useEffect(() => {
        seo.setSeo(SeoPage.partite)
        Promise.all([
            api.GetActiveEventIssuers().then(r => {
                setEventIssuers(r)
            }),
            api.GetActiveEvents().then(r => {
                setEvents(r)
            })
        ]).then(() => {
            setLoaded(true)
        })
    }, [])
    if (!events || !eventIssuers || !loaded) {
        return <Loader/>
    }
    const updateFilter = (f: string[]): void => {
        setFilter(f)
    }
    const fEvents = events.filter(e => {
        if (filter.length === 0) {return true}
        return (filter.includes(e.mainIssuerUuid) || filter.includes(e.secondaryIssuerUuid))
    })
    return (
        <Layout>
            <div className={(events.length === 0) ? "plp plp--sold-out container" : "plp container"}>
                <div className="plp__header row justify-content-center">
                    <div className="col-12 text-center plp__header__title">
                        <h2>Vivi l'esperienza <strong>Grigiorossa</strong></h2>
                    </div>
                    <div className="col-12 col-sm-10 col-md-8 col-xl-6 text-center plp__header__subtitle">
                        <p>
                            Acquista uno o più biglietti per la Cremonese con la <strong>Cremocard</strong>.
                        </p>
                    </div>
                </div>

                <div className={(events.length === 0) ? "plp__contents plp__contents--sold-out row" : "plp__contents row justify-content-center"}>
                    {
                        (events.length !== 0)
                            ?
                            <>
                                <PLPFilters teams={eventIssuers} updateFilter={updateFilter}/>
                                <div className="col-12 col-lg-8 ms-xl-3 row games d-flex justify-content-between">
                                    {
                                        fEvents.length > 0 ?
                                            <>
                                                {
                                                    fEvents.map((ev,i) => {
                                                        return <PLPCard event={ev} key={i}/>
                                                    })
                                                }
                                            </>
                                            :
                                            <div className="row games__not-found">
                                                <div className="col-12 text-center">
                                                    <h3 className="games__not-found__label">{"Nessun risultato"}</h3>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </>
                            :
                            <SoldOut page={"plp"}/>
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Plp
