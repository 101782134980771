import React, {ReactElement, useEffect} from 'react'
import Info from "@/components/Info";

import data from "@/static/json/frequentlyAskedQuestions.json";

import Layout from "@/components/Layout";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";

const Faq = ({...props}):ReactElement => {
    const seo = useSeo();
    useEffect(() => {
        window.scrollTo(0, 0)
        seo.setSeo(SeoPage.faq);
    })
    return (
      <Layout>
          <Info
              title={data.title}
              subtitle={data.subtitle}
              accordionId={data.accordionId}
              accordionSection={data.accordionSections}
          />
      </Layout>
  )
}

export default Faq
