import {ReactElement, useEffect, useState} from 'react'
import {Link, useNavigate} from "react-router-dom";
import Loader from "@/components/Loader";
import {PreorderState} from "@/models/Preorder";
import {useApi} from "@/hoc/Api/context";
import Layout from "@/components/Layout";
import CartTickets from "@/components/CartTickets";
import {toast} from "react-toastify";
import {useStore} from "@/hoc/Store/context";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";

const Carrello = ({...props}): ReactElement => {
    const seo = useSeo()
    const api = useApi()
    const store = useStore()
    const navigate = useNavigate()

    const [permissionChecked, setPermissionChecked] = useState<boolean>(false);

    const preorder = store.preorder()

    const loadPreorder = (): void => {
        api.GetPreorder().then(p => {
            if (p.state === PreorderState.Draft || p.state === PreorderState.Pending) {
                store.setPreorder(p)
            } else {
                navigate("/Checkout")
            }
        })
    }

    const getMembershipCards = (): void => {
        api.GetMembershipCards().then(cards => {
            store.setMembershipCard(cards)
        })
    }

    const proceedToCheckout = (): void => {
        if (preorder?.ticketOrders) {
            for (let ticket in preorder.ticketOrders) {
                if (!preorder.ticketOrders[ticket].targetMembershipCardUuid) {
                    toast.error("Devi selezionare una carta per ogni biglietto")
                    return
                }
            }
        }
        navigate(`/Checkout`)
    }

    const areTicketsSelected = (): boolean => {
        let selected = true;
        store.preorder()?.ticketOrders.forEach(t => {
            selected = selected && t.targetMembershipCardUuid != null
        })
        return selected;
    }

    useEffect(() => {
        seo.setSeo(SeoPage.carrello)
        loadPreorder()
        getMembershipCards()
    }, [])

    if (preorder === undefined) {
        return <Loader/>
    }

    return (
        <Layout>
            {preorder.ticketOrders !== undefined && preorder.ticketOrders.length > 0 ?
                <div className="shopping-cart container">
                    <div className="shopping-cart__title row">
                        <div className="col-12">
                            <h2 className="shopping-cart__title__text text-left font-weight-semibold">
                                {"Shopping Cart"}
                            </h2>
                        </div>
                    </div>

                    <CartTickets/>

                    <div className="shopping-cart__checkout row justify-content-end">
                        <div className="col-12 px-3">
                            <p className="shopping-cart__checkout__info label-2">
                                * Questo è il prezzo definitivo inclusivo del costo del biglietto, dei costi del
                                servizio di Ticketag e della piattaforma di pagamento “Stripe”
                                <br/>
                                ** Prezzo di facciata del singolo biglietto intero al botteghino.
                            </p>
                        </div>
                        <div className="shopping-cart__checkout__total col-12 col-md-6 justify-content-center">
                            <div className="shopping-cart__checkout__total__full">
                                <label className="font-weight-semibold ms-2 text--primary-color">{"Totale"}</label>
                                <label className="font-weight-medium me-2 text--primary-color">
                                    € {((preorder.totalAmount < 0.0) ? 0.0 : preorder.totalAmount / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}
                                </label>
                            </div>
                            <div className="form-group form-check shopping-cart__checkout__total__recess px-3">
                                <div className={"row py-2"}>
                                    <div className={"col-auto"}>
                                        <div className={`form-group form-check`}>
                                            <input type="checkbox"
                                                   className={`form-check-input`}
                                                   id={"readTerms"}
                                                   name={"readTerms"}
                                                   onClick={() => setPermissionChecked(!permissionChecked)}
                                                   checked={permissionChecked}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col p-0"}>
                                        <label className="form-check-label">
                                            Accetto l’impossibilità di esercitare il diritto di recesso completato
                                            l’acquisto
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col d-flex justify-content-center"}>
                                    <button
                                        className={(permissionChecked) ? "btn btn--gradient w-100 shadow-none" : "btn btn--gradient w-100 shadow-none disabled"}
                                        onClick={proceedToCheckout}
                                        disabled={!(permissionChecked && areTicketsSelected())}>
                                        {"Procedi al checkout"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="shopping-cart--empty container">
                    <div className="col-12">
                        <h2 className="shopping-cart--empty__title">{"Shopping cart"}</h2>
                    </div>
                    <div className="col-11 col-md-9 col-lg-5">
                        <p className="shopping-cart--empty__subtitle">{"Non hai nessun articolo nel carrello!\n Inizia ad acquistare dei biglietti."}</p>
                    </div>
                    <div className="col-12">
                        <Link to="/Partite">
                            <button type="button"
                                    className="shopping-cart--empty__continue btn btn--gradient">{"Continua lo shopping"}</button>
                        </Link>
                    </div>
                </div>
            }
        </Layout>
    )
}

export default Carrello
