import {ReactElement} from "react";
import {useNavigate} from "react-router-dom";
import Event from "@/models/Event"
import Moment from "react-moment";

interface PLPCardProps {
    event: Event;
}


const PLPCard = (props: PLPCardProps): ReactElement => {
    const navigate = useNavigate();
    return (
        <div className="col-12 col-md-6 px-3 game d-flex cursor-pointer" onClick={() => {
            navigate(`/Partita/${props.event.uuid}`)
        }}>
            <div className="game__card text-center">
                <div className="game__card__image">
                    <img
                        alt={`Biglietti ${props.event.mainIssuer} vs ${props.event.secondaryIssuer}`}
                        title={`Biglietti ${props.event.mainIssuer} vs ${props.event.secondaryIssuer}`}
                        src={props.event.previewImage}
                        className="card-img-top"/>
                    <label className="game__card__image__label">{props.event.championship}</label>
                </div>
                <h3 className="game__card__title text--heading-color px-2">
                    <span className="font-weight-medium"> {props.event.mainIssuer} </span>
                    vs
                    <span className="font-weight-medium"> {props.event.secondaryIssuer} </span>
                </h3>
                <p className="game__card__subtitle">
                    {props.event.eventLocation}, <span
                    className="font-weight-semibold text-capitalize text--primary-color">
                    <Moment locale={"it"} format="ddd D MMMM">{props.event.date}</Moment></span>
                </p>
                <div className="game__card__sell">
                    {(props.event.startingPrice) ?
                        <div className="game__card__sell__prices-start">
                            <label>{"A partire da"}</label>
                            <h5 className="game__card__sell__price">{`€ ${(props.event.startingPrice / 100).toLocaleString('it-IT', {minimumFractionDigits: 2})}`}</h5>
                        </div>
                        :
                        null
                    }
                    <div className={(!props.event.startingPrice) ? "col-12" : ""}>
                        <button className="btn btn--outlined">
                            <span className="btn__text-gradient">{"Biglietti"}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PLPCard
